:root {
  --primary-color: #04384A;
  --secondary-color: #DBCB81;
  --secondary-color-light: #F5F0D7;
  --gray-background: #F5F5F5;
  --footer-background: #0B2C37;

  --text-introduction: #706016;
  --white: #FFF;
  --black: #000;

  --menu-bg-item-color: #053546;
  --menu-font-size: 1.8rem;

  --roboto: 'Roboto', sans-serif;
  --montserrat: 'Montserrat', sans-serif;
}



* {
  font-size: 62.5%;
}

body {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  width: 100%;
}

p {
  text-align: justify !important;
}

.background-map {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1000;
  opacity: .1;
}

.section-introduction {
  font-family: var(--montserrat);
  font-size: 2rem;
  font-weight: 400;
  color: var(--text-introduction);
  text-transform: uppercase;
  text-align: center;
}

.section-title {
  font-family: var(--montserrat);
  font-size: 2.4rem;
  font-weight: 800;
  color: var(--primary-color);
  text-align: center;
  padding: 0rem 2rem;
}

.section-description {
  text-align: center;
  font-size: 1.8rem;
  max-width: 40rem;
  margin: auto;
  font-weight: 400;
  font-family: var(--montserrat);
}



.request-button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 2rem 4rem;
  border: none;
  border-radius: .5rem;
  font-size: 2rem;
  outline: none;
}

.request-button > img {
  width: 4rem;
  margin-right: 2rem;
}

.top-bar {
  background-color: var(--primary-color);
  color: var(--white);
}

.top-bar * {
  font-size: 1.4rem;
  padding: .3rem 0rem;
}

.icon-topbar {
  width: 2rem;
  margin-right: 1rem;
}

header {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: var(--white);
  border-bottom: 3px solid var(--secondary-color);
}

.logo {
  max-width: 20rem;
}


.menu > .mobile-menu {
  background: var(--primary-color);
  position: fixed;
  width: 90%;
  max-width: 45rem;
  height: 100vh;
  z-index: 10;
  top: 0;
  right: 0;
  color: var(--white);
  padding: 0rem 2rem;
  overflow: auto;
  border-left: 5px solid var(--secondary-color);
  font-family: var(--montserrat);
  animation: mobileMenuReveal .5s forwards;
}

.menu > .mobile-menu > .close-menu-button {
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 600;
  background-color: var(--secondary-color);
}

.menu > .mobile-menu > div > ul > a > li, .menu > .mobile-menu > div > ul > a {
  font-size: 1.8rem;
  font-weight: 400;
  width: 100%;
  text-decoration: none;
  color: var(--white);
}

.menu > .mobile-menu > div > ul > a > li {
  border-bottom: 1px solid var(--secondary-color);
}

.menu > .mobile-menu > div > ul > a:last-of-type > li {
  border-bottom: none;
}


.menu > .mobile-menu > .info {
  font-size: 2.8rem;
  justify-content: center;
  font-family: var(--roboto);
}

.menu > .mobile-menu > .info > div {
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  min-width: 20rem;
}

.menu > .mobile-menu .icon {
  width: 2rem;
}

.menu-button {
  width: 5rem;
  background: none;
  border: none;
  outline: none;
}

.menu-button > img {
  width: 100%;
}

.header-img {
  overflow: hidden;
  min-height: 40rem;
  max-height: 50rem;
  padding: 2rem 0rem;
  position: relative;
}

.header-img > img {
  width: 140%;
}

.header-text {
  background: rgba(5, 53, 70, .5);
  color: var(--white);
}

.header-text h1 {
  font-size: 2.4rem;
  font-weight: 600;
  font-family: var(--montserrat);
}

.header-text p {
  font-size: 1.6rem;
  font-family: var(--roboto);
}

.alert {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 0px 0px 2rem 0rem;
}

.alert h4 {
  font-size: 1.8rem;
}

.alert a {
  font-size: inherit;
}

.services {
  background-color: var(--gray-background);
}


.hold-image {
  height: 22rem;
  overflow: hidden;
  border-radius: 5px;
}

.hold-image > img {
  width: 100%;
}

.services .hold-image > img {
  width: auto;
  height: 80%;
}

.service {
  border-bottom: 5px solid var(--secondary-color);
  padding: 2rem 1rem;
}

.service-category {
  font-family: var(--montserrat);
  font-size: 1.8rem;
  font-weight: 300;
  color: var(--text-introduction);
  text-transform: uppercase;
  margin-top: 1rem;
  padding: 0rem 1rem;
}

.service-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--primary-color);
  padding: 0rem 1rem;
}

.service-description * {
  font-size: 1.6rem;
  padding: 0rem 1rem;
}


.service-description a {
  padding: 0;
}

.service-description p {
  font-size: 1.8rem;
}


.service-description li {
  margin-bottom: 1rem;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
}

.service-description li a {
  font-size: 2rem;
}

.service-description li::before {
  content: '';
  width: 2rem;
  height: 2rem;
  display: block;
  margin-right: 1rem;
  background-image: url('../images/icon-check.png');
  background-size: cover;
  flex: none;
}

.service-button {
  width: 90%;
  padding: 1.5rem 2rem;
  margin-top: 2rem;
  border: none;
  outline: none;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 2rem;
  text-align: center;
  border-radius: .5rem;
}

.service-button-page {
  max-width: 30rem;
}

.service-detail {
  border-bottom: 3px solid var(--secondary-color);
  padding: 2rem 0rem;
}

.service-detail > .hold-image {
  height: 22rem;
  overflow: hidden;
  border-radius: 2rem;
}


.service-detail h5 {
  font-family: var(--montserrat);
  font-size: 2rem;
  font-weight: 300;
  color: var(--text-introduction);
  text-transform: uppercase;
  margin-top: 1rem;
  padding: 0rem 1rem;
}

.service-detail h4 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-color);
  padding: 0rem 1rem;
}


footer {
  background-color: var(--footer-background);
  color: var(--white);
  border-top: 1rem solid var(--secondary-color);
}

.footer-logo {
  max-width: 18rem;
}

footer h5 {
  font-family: var(--montserrat);
  color: var(--secondary-color);
}

footer li, footer li a, footer li span {
  font-size: 1.6rem;
  margin-bottom: .5rem;
  color: var(--white);
}

footer li:hover, footer li a:hover {
  color: var(--secondary-color)
}

.footer-icon {
  width: 1.5rem;
  margin-right: .5rem;
}

.footer-address {
  font-size: 1.6rem;
  margin-top: -0.4rem;
}

.footer-address > span {
  font-weight: lighter;
  font-size: 1.4rem;
}

.footer-stroke {
  border-top: 1px solid var(--secondary-color);
}

.footer-stroke > p {
  font-size: 1.4rem;
}

.footer-stroke > .copyright {
  color: var(--secondary-color);
}

main, section, article {
  animation: containerReveal 1s forwards;
}


.scheduling {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background: rgba(0, 0, 0, .2);
}

.scheduling > div {
  width: 100%;
  height: 80vh;
  position: fixed;
  overflow-y: auto;
  background-color: var(--white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  max-width: 80rem;
  border-radius: 10px;
}

.schedule-day {
  width: 48%;
  font-size: 2.2rem;
  font-weight: bolder;
  text-align: center;
  max-width: 23rem;
  padding: 1rem;
  border: 2px solid var(--primary-color);
  margin-bottom: 1.5rem;
  border-radius: 5px;
  background: var(--white);
}

.schedule-day:hover {
  background: var(--primary-color);
  color: var(--white);
}

.schedule-day > p {
  margin-bottom: 0px;
  font-size: 2rem;
  color: var(--text-introduction);
}

.schedule-day:hover > p {
  color: var(--secondary-color-light);
}

.scheduling-form label {
  font-size: 2rem;
}

.scheduling-form input, .scheduling-form textarea {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.scheduling-form button {
  font-size: 2rem;
  border: none;
  outline: none;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 1.5rem 2rem;
  border-radius: 5px;
}

.faq-info {
  border-left: 4px solid var(--secondary-color);
  margin-left: 2rem;
  margin-top: 1rem;
}

.faq-item {
  background-color: var(--gray-background);
  border-radius: 5px;
  border: 2px solid var(--secondary-color);
  cursor: pointer;
  height: auto;

}

.faq-icon {
  width: 3rem;
}

.faq-item h3 {
  font-size: 1.8rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  font-weight: 600;
}

.faq-item p, .faq-item a {
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-left: .5rem;
  animation: showParagraph 1s forwards;
}

.faq-button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 2rem 4rem;
  border: none;
  outline: none;
  border-radius: .5rem;
  font-size: 2rem;
  opacity: .7;
}

.faq-button:hover, .faq-button.active {
  opacity: 1;
}

.gallery-item > .hold-image {
  height: 30rem;
}

.gallery-zoom {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;
}

.gallery-item:hover .gallery-zoom {
  display: flex;
}

.gallery-zoom > img {
  width: 6rem;
} 

.gallery-lightbox {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(66, 59, 59, 0.2);
  z-index: 16;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hold-lightbox-image {
  max-width: 45rem;
}

.hold-lightbox-image > img {
  width: 100%;
}

.gallery-lightbox .close-button {
  position: absolute;
  top: 2%;
  right: 0%;
  z-index: 1000;
  font-size: 2rem;
  border: none;
  outline: none;
  color: var(--white);
  padding: .5rem 2rem;
  border-radius: 5px;
  background: none;
}

.gallery-lightbox .close-button > img {
  transform: rotate(45deg);
}

.useful-item {
  border: 2px solid var(--primary-color);
  padding: 1.5rem 2rem;
  border-radius: 10px;
}

.useful-item a {
  color: var(--black);
}

.contact-row {
  margin-bottom: 1.5rem;
}

.contact-row * {
  font-weight: bolder;
  font-size: 2.2rem;
}

.contact-row > img {
  width: 3rem;
  margin-right: 1rem;
}

.contact-border {
  border-bottom: 4px solid var(--secondary-color);
}

.lgpd * {
  font-size: 1.6rem;
}

.lgpd table {
  width: 100%;
}

.lgpd table td {
  border: 1px solid var(--black);
  padding: .5rem 1rem;
}

.lgpd p {
  font-size: 1.6rem;
}

.close-button {
  background: none;
  border: none;
  outline: none;
}

.close-button > img {
  transform: rotate(45deg);
}

.about-us-text > h2 {
  font-weight: bolder;
}

.record-status {
  font-size: 2.2rem;
  margin-top: .5rem
}

.advices {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  max-width: 60rem;
  max-height: 60rem;
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);
  z-index: 1000;
  padding: 4rem;
  border: 5px solid var(--primary-color);
}

.advices h1, h2 {
  font-family: var(--montserrat);
}

.advices h2 {
  color: var(--primary-color);
}

.advices p {
  font-size: 1.8rem;
}

.hold-advices {
  margin-top: -2rem;
}

.lgpd-agreements {
  position: fixed;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1024px;
  background: var(--white);
  z-index: 1000;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .4);
  border: 1px solid var(--primary-color);
}

.lgpd-agreements * {
  font-size: 1.6rem;
}

.lgpd-agreements a {
  font-weight: bold;
  color: var(--primary-color);
}

.lgpd-agreements button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 1rem 2rem;
}

@media screen and (min-width: 768px) {

  .menu > .mobile-menu > .info {
    font-size: 2.6rem;
  }


  .header-text {
    background: url('../images/city-view.jpg');
    background-size: 150%;
    background-position: center;
    border-top-right-radius: 2rem;
    box-shadow:inset 0 0 0 2000px rgba(5, 53, 70, 0.8);
    
  }

  .header-text p {
    font-size: 1.6rem;
  }

  .request-button {
    padding: 2rem 3rem;
  }

  .request-button > img {
    width: 4rem;
    margin-right: 2.2rem;
  }

  .header-img > img {
    width: auto;
    height: 130%;
  }

  .service > .service-category {
    font-size: 1.8rem;
  }
  
  .service > .service-title {
    font-size: 2rem;
  }
  
  .service > .service-description {
    font-size: 1.4rem;
  }

  .footer-address {
    font-size: 1.2rem;
  }

  .footer-address > span {
    font-size: 1.2rem;
  }

  
  .hold-lightbox-image {
    max-width: 60rem;
  }

  .contact-row {
    margin-bottom: 1rem;
  }

  .contact-row * {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .contact-row > img {
    width: 2.5rem;
    margin-right: .75rem;
  }

}

@media screen and (min-width: 992px) {
  .logo {
    width: 80%;
    max-width: 15rem;
    transition: all 2s step-end 2s;
    animation: logoFade .5s forwards;
  }

  .hold-menu {
    min-height: 17rem;
  }

  .header-text h1 {
    font-size: 2.5rem;
  }

  .header-text p {
    font-size: 1.6rem;
  }
  
  .wide-menu {
    background: var(--secondary-color-light);
    border-radius: 10px;
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  
  header {
    position: inherit;
    top: 0;
    z-index: 9;
    background-color: var(--white);
    border-bottom: none;
  }

  .sticky-menu {
    position: fixed;
    top: 0;
    z-index: 10;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
    border-radius: 0px 0px 10px 10px;
    width: 100vw;
    justify-content: center;
  }

  .sticky-menu > ul {
    max-width: 1140px;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .section-description {
    font-size: 1.8rem;
    max-width: 50rem;
  }

  .service {
    border-bottom: none;
    border-left: 2px solid var(--secondary-color);
  }

  .service:first-child {
    border-left: none;
  }

  .service-detail > .hold-image {
    max-width: 30rem; 
  }

  .service-detail h4 {
    font-size: 2.2rem;
  }

  .wide-menu > ul > li, .wide-menu > ul > li > a {
    font-size: 1.6rem;
    font-family: var(--roboto);
    font-weight: 600;
    color: var(--primary-color);
    text-decoration: none;
  }

  .header-img {
    height: 45rem;
  }

  .header-img > img {
    width: 120%;
    height: auto;
  }

  .request-button > img {
    width: 4rem;
    margin-right: 1.6rem;
  }

  .alert h4 {
    font-size: 2rem;
  }

  .request-button {
    padding: 1.6rem 4rem;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  
  .service > .service-title {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--primary-color);
  }
  
  .service > .service-description {
    font-size: 1.4rem;
  }

  footer li, footer li a, footer li span {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  footer li:hover, footer li a:hover {
    color: var(--secondary-color);
  }

  .footer-address {
    font-size: 1.6rem;
  }

  .footer-icon {
    width: 2rem;
  }

  .faq-item h3 {
    font-size: 2rem;
  }

  .hold-lightbox-image {
    max-width: 80rem;
  }

  
  .contact-border {
    border-bottom: none;
    border-right: 4px solid var(--secondary-color);
  }

  .schedule-day {
    font-size: 2.4rem;
    width: 33%;
  }

  
  .services {
    min-height: 80rem;
  }
  
}


@media screen and (min-width: 1200px) {
  .wide-menu > ul > li {
    font-size: 2rem;
  }

  .logo {
    width: 100%;
    max-width: 18rem;
    
  }

  .header-img {
    height: 50rem;
  }

  .header-text h1 {
    font-size: 3rem;
  }

  .header-text p {
    font-size: 1.8rem;
  }

  
  .top-bar * {
    font-size: 1.6rem;
  }

  .section-description {
    max-width: 65rem;
  }

  .service > .service-title {
    font-size: 2.2rem;
}

  .service:nth-child(odd) {
    border-left: 2px solid var(--secondary-color);
  }

  .service:first-child {
    border-left: none;
  }

  .wide-menu > ul > li, .wide-menu > ul > li > a {
    font-size: 1.8rem;
  }

  .request-button {
    padding: 2.5rem 4rem;
    font-size: 2.2rem;
    outline: none;

  }
  
  .section-introduction {
    font-size: 2.4rem;
  }

  .section-title {
    font-size: 3rem;
  }
  
  .section-description {
    font-size: 2rem;
  }

  .service > .service-description {
    font-size: 1.6rem;
  }

  .alert h4 {
    font-size: 2.2rem;
  }

  footer li, footer li a, footer li span {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: var(--white);
  }

  .footer-address, .footer-address > span {
    font-size: 1.6rem;
  }

  .footer-logo {
    max-width: 18rem;
  }

  .footer-icon {
    width: 2rem;
  }

  

  footer h5 {
    font-size: 1.6rem;
  }

  .footer-stroke > p {
    font-size: 1.6rem;
  }
  
}

@keyframes logoFade {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}

@keyframes mobileMenuReveal {
  0% {
    right: -300px;
  } 100% {
    right: 0px;
  }
}

@keyframes containerReveal {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
  }
}

@keyframes showParagraph {
  0% {
    opacity: 0;
  } 100% {
    opacity: 1;
    height: auto;
  }
}